<template>
  <div class="produto-resumido-component overflow-hidden">
    <div class="produto-resumido-imagem" :style="'--quadrado: ' + w + 'px'" v-if="imagens">
      <Image :src="imagens[0]" alt="Produto" class="block" imageClass="max-w-full h-auto block" @click="showDetalhe" />
    </div>
    <div class="produto-resumido-titulo text-center text-lg mt-2 px-1 w-full">
      <span class="w-full">{{ nome }}</span>
      
    </div>
    <div class="text-center text-lg mt-2" v-if="valorPromocao !=''">
      <span class="cortado mr-2">{{ valorAtacado }}</span>
      <span class="font-bold text-lg" >{{ valorPromocao }}</span>
    </div>
    
    <div v-else class="produto-resumido-preco text-center font-bold text-lg mt-2 w-full">
      <span class="w-full">
        <span
          v-if="$root.empresa.configuracoes.ECOMMERCE_VALORES_ATACADO_VISIVEL || $root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO"
          v-tooltip.top="($root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO) ? { value: 'A partir de ' + $root.empresa.configuracoes.ECOMMERCE_VALORES_ATACADO_QUANTIDADE + ' ítens no pedido', class: 'text-xs' } : null">
          <span v-show="$root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO">A: </span>{{ valorAtacado }} <sup
            v-show="$root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO">*</sup>
        </span><br>
        <span
          v-if="$root.empresa.configuracoes.ECOMMERCE_VALORES_VAREJO_VISIVEL || $root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO"
          class="font-medium text-base mt-1">
          <span v-show="$root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO">V: </span>{{ valorVarejo }}
        </span>
      </span>
    </div>
    <div class="produto-resumido-acoes text-center text-lg mb-2 mt-2">
      <Button label="Adicionar" icon="pi pi-shopping-cart" class="botao-comprar p-button-text md:mr-3" @click="addItem" />
      <Button icon="pi pi-heart-fill" class="botao-favoritar p-button-text" @click="likeItem" v-if="favorito" />
      <Button icon="pi pi-heart" class="botao-favoritar p-button-text" @click="likeItem" v-else />
      <Button icon="pi pi-share-alt" class="p-button-text" @click="shareItem" />
    </div>
  </div>

  <Dialog :position="($utils.isMobileCheck()) ? 'bottom' : 'center'" v-model:visible="displayAdicionarCarrinho"
    style="max-width: 98%" :modal="true" :closable="true">
    <template #header>
      <h5 class="m-0 text-xl font-medium"><i class="pi pi-shopping-cart" style="font-size: 1.3rem"></i> Adicionar ao
        Carrinho</h5>
    </template>
    <AdicionarCarrinho :value="value.id" />
  </Dialog>

  <Sidebar :position="($utils.isMobileCheck()) ? 'right' : 'right'" v-model:visible="displayDetalhe"
    :style="{ width: ($utils.isMobileCheck())  ? '450px' : '750px', 'max-width': '90%' }" :modal="true" :closable="true">
    <template #header>
      <h5 class="m-0 text-xl font-medium " ><i class="text-left pi pi-ticket" style="font-size: 1.3rem"></i> Detalhes do Produto</h5>
    </template>
    <DetalhesProduto :value="value" />
  </Sidebar>
</template>

<script>

import DetalhesProduto from './DetalhesProduto.vue';

export default {
  components: { DetalhesProduto },
  name: "ProdutoResumido",
  props: {
    value: Object,
  },
  emits: [
    'add',
    'like'
  ],
  data() {
    return {
      displayAdicionarCarrinho: false,
      w: 0,
      favorito: false,
      displayDetalhe: false,
    };
  },
  mounted() {

    const self = this;

    this.w = this.$el.parentElement.offsetWidth;

    const resizeObserver = new ResizeObserver(elements => {
      for (let e of elements) {
        self.w = e.borderBoxSize[0].inlineSize;
      }
    });

    resizeObserver.observe(this.$el.parentElement);

    //this.verificaFavorito();
  },
  computed: {
    nome() {
      if (this.value && typeof this.value.nome != "undefined" && this.value.nome) {
        return this.value.nome;
      }
      return "";
    },
    imagens() {
      if (this.value && typeof this.value.imagens != "undefined" && this.value.imagens) {
        return this.value.imagens;
      }
      return [];
    },
    valorAtacado() {
      if (this.value && typeof this.value.valorAtacado == "number") {
        return this.$utils.formatCurrency(this.value.valorAtacado);
      }
      return "";
    },
    valorVarejo() {
      if (this.value && typeof this.value.valorVarejo == "number") {
        return this.$utils.formatCurrency(this.value.valorVarejo);
      }
      return "";
    },
    valorPromocao() {
      //console.log(this.value.valorPromocao)
      if (this.value && typeof this.value.valorPromocao == "number") {
        if(this.value.valorPromocao <= 0){
          return "";
        }
        return this.$utils.formatCurrency(this.value.valorPromocao);
        
      }
      return "";
    },
  },
  methods: {

    addItem() {
      this.displayAdicionarCarrinho = true;
      this.$emit("add", this.value);
    },
    likeItem: async function () {
      // const res = await this.$api.get("/setfavorito/"+this.value.id);
      // const dados = res.data;
      // if(dados.success){
      //   this.favorito = dados.favorito;
      //   this.$emit("like", this.value);
      // }

    },
    verificaFavorito: async function () {
      // const res = await this.$api.get("/verificafavorito/"+this.value.id);
      // const dados = res.data;
      //   this.favorito = dados.success;

    },
    async shareItem() {

      let link =
        "https://loja.npc.software/" +
        this.$root.empresa.id +
        "#" +
        "/produto-detalhes/" +
        this.$root.empresa.id +
        "/" +
        this.value.id;
     
      let body = {
        "titulo": this.value.nome,
        "descricao": "Varejo: "+this.$utils.formatCurrency(this.value.valorVarejo)+" Atacado: "+this.$utils.formatCurrency(this.value.valorAtacado),
        "imagem": this.imagens[0],
        "url": link
      };
      const ret = await this.$apiv3.post("/v2/produtos-gerar-link-dinamico",body);
      console.log(ret.data)
      window.open(
        "https://api.whatsapp.com/send?&text=" + (ret.data.success ? ret.data.data.shortLink : link)
      );

      this.$emit("share", this.value);
    },
    showDetalhe() {
      this.displayDetalhe = true;
    }

    /*abrirLinkPagamentoSemDominio(l) {
      let w = l.clientes.fone;
      let link =
        "https://loja.npc.software/" +
        this.usuario.empresas.id +
        "/pagamento/" +
        l.id;
      window.open(
        "https://api.whatsapp.com/send?l=pt_pt&phone=55" + w + "&text=" + link
      );
    },*/

  },
};
</script>

<style lang="scss">
.produto-resumido-component {
  background-color: $alternativeBackgroundColor;
  border-radius: 0.3rem;
}

.produto-resumido-titulo,
.produto-resumido-preco {
  height: 2.8rem;
  display: table;
}

.produto-resumido-titulo span,
.produto-resumido-preco span.w-full {
  display: table-cell;
  vertical-align: middle;
}
</style>

<style lang="scss" scoped>
.p-button.p-button-text {
  color: $alternativeColor;
  font-size: 1rem;
}

.p-button.p-button-text:enabled:hover {
  color: $primaryColor;
  background: none;
}

.p-button {
  @media screen and (max-width: 500px) {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
}

.cortado {
  text-decoration: line-through; /* Aplica o efeito riscado */
  /*color: red;                    /* Opcional: destaca o preço como incorreto */
}

sup {
  font-size: 0.7rem;
}
</style>