<template>
  <div class="grid p-fluid">
    <div class="col-12 md:col-6">
      <div class="shadow-none border-round-lg bg-white" style="overflow: hidden;">
        <Galleria :value="adicionais.imagens" :responsiveOptions="responsiveOptions" :numVisible="5"
          containerStyle="max-width: 100%" :showThumbnails="false" :showIndicators="true"
          :showItemNavigatorsOnHover="true" :showItemNavigators="true" :autoPlay="isAutoPlay" :circular="true"
          :transitionInterval="5000">
          <template #item="slotProps">

            <Image v-if="typeof slotProps.item == 'string'" :src="slotProps.item" alt="Logo"
              imageClass="max-w-full h-auto block min-h-full" />

            <video controls autoplay v-else>
              <source :src="slotProps.item.url">

            </video>
          </template>
        </Galleria>
      </div>

    </div>
    <div class="col-12 md:col-6" v-if="adicionais && !carregando">
      <div class="shadow-none border-round-lg bg-white">
        <div class="grid">
          <div class="col-12">
            <div class="ml-2">
              <span class="w-full titulo">{{ adicionais.nome + " Ref: " + adicionais.referencia }}</span>
            </div>
            <div class="text-left px-2 col-12">
              <div class="">Descrição: {{ adicionais.descricao }}</div>
            </div>
            
            <div class="text-right mr-4" v-if="adicionais.valorPromocao">
              <span class="cortado mr-2">{{ $utils.formatCurrency(adicionais.valorAtacado) }}</span>
              <span class="font-bold text-lg" >{{ $utils.formatCurrency(adicionais.valorPromocao) }}</span>
            </div>
            <div class="text-right mr-4" v-else>
              <span class="w-full ">
                <span class="titulo-valor"
                  v-if="$root.empresa.configuracoes.ECOMMERCE_VALORES_VAREJO_VISIVEL || $root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO">
                  V: {{ $utils.formatCurrency(adicionais.valorVarejo) }}
                </span><br>
                <span
                  v-if="$root.empresa.configuracoes.ECOMMERCE_VALORES_ATACADO_VISIVEL || $root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO"
                  class="titulo-valor"
                  v-tooltip.top="($root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO) ? { value: 'A partir de ' + $root.empresa.configuracoes.ECOMMERCE_VALORES_ATACADO_QUANTIDADE + ' ítens no pedido', class: 'text-xs' } : null">
                  A: {{ $utils.formatCurrency(adicionais.valorAtacado) }} <sup
                    v-show="$root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO"></sup>
                </span>
              </span>
            </div>

            <div class="col-12">
              <AdicionarCarrinho :value="value.id" />
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-12" v-if="adicionais && !carregando">
      <div class="shadow-none border-round-lg bg-white">
        <div class="grid">
          <div class="col-12 ml-2"><label for="" style="font-weight: bold;">Categoria</label></div>
          <div class="col-12 ml-2" style="margin-top: -1rem;">{{ adicionais.categoria }}</div>
          <div class="col-12 ml-2"><label for="" style="font-weight: bold;">Subcategoria</label></div>
          <div class="col-12 ml-2" style="margin-top: -1rem;">{{ adicionais.subCategoria }}</div>
          <div class="col-12 ml-2"><label for="" style="font-weight: bold;">Descrição</label></div>
          <div class="col-12 ml-2" style="margin-top: -1rem;">{{ adicionais.descricao }}</div>

          <div class="col-12 ml-2"><label for="" style="font-weight: bold;">Similares</label></div>
          <div class="col-12" style="margin-top: -1rem;">
            <div v-for="h of produtos_similares" :key="h" :id="h.id">
              <div class="grid mt-3">
                <div :class="'col-' +
          12 / 2 +
          ' md:col-' +
          12 / 4
          " v-for="i of h.itens" :key="i.id">
                  <component :is="'ProdutoResumido'" :value="i"></component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading" v-show="carregando">
    <div class="center-spinner" v-show="carregando">
      <ProgressSpinner />
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
export default {
  name: "DetalhesProduto",
  props: ["value"],
  data() {
    return {
      // Controle de apresentação
      carregando: true,
      informacoesValidadas: false,
      isAutoPlay: false,
      produtos_similares: [],

      // Dados
      quantidade: 1,
      tamanho: null,
      cor: null,
      valor: 0,
      adicionais: {},
      responsiveOptions: [
        {
          breakpoint: '1024px',
          numVisible: 5
        },
        {
          breakpoint: '768px',
          numVisible: 3
        },
        {
          breakpoint: '560px',
          numVisible: 1
        }
      ],
      dataEntrada: moment().tz("America/Sao_Paulo").toDate(),
      usuario:null
    };
  },
  emits: ["add"],
  mounted() {
    this.carregando = false;
    this.carregaInformacoes();
  },
  computed: {

    valorAtacado() {
      if (this.value && typeof this.value.valorAtacado == "number") {
        return this.$utils.formatCurrency(this.value.valorAtacado);
      }
      return "";
    },
    valorVarejo() {
      if (this.value && typeof this.value.valorVarejo == "number") {
        return this.$utils.formatCurrency(this.value.valorVarejo);
      }
      return "";
    },
  },
  beforeUnmount() {
        console.log("Chamou")
        this.atualizar_comportamento();

    },
  methods: {

    async carregaInformacoes() {
      this.carregando = true;

      const response = await this.$apiv3.get("/v2/produtos/" + this.value.id);
      const dados = response.data;

      if (dados.success) {
        this.adicionais = dados.data;
        this.valor = dados.data.valorVarejo;
        let imagens = [];
        if (typeof this.adicionais.video != "undefined" && typeof this.adicionais.video.url != "undefined") {

          imagens.push(this.adicionais.video);
          this.isAutoPlay = false;

        }
        for (let c of Object.values(this.adicionais.cor)) {
          if (typeof c.foto1 != "undefined") {
            imagens.push(c.foto1);
          }
          if (typeof c.foto2 != "undefined") {
            imagens.push(c.foto2);
          }
          if (typeof c.foto3 != "undefined") {
            imagens.push(c.foto3);
          }
        }
        this.adicionais.imagens = imagens;
        this.carregando = false;
        const ret = await this.$apiv3.get("/v2/produtos?fields=nome,valorAtacado,valorVarejo,imagen1,id,qnt&categoria=" + this.adicionais.categoria + "&perPage=20&tirarWeb=\\false");
        for (let p of ret.data.data) {
          p.imagens = [p.imagen1];
        }
        this.produtos_similares = [{ nome: "Similares", itens: ret.data.data }];

      }


    },
    async atualizar_comportamento() {


      let body = {
        "origem": "web",
        "acaoSeguinte": "",
        "pedido": {},
        "query": {},
        "categoria": "Visualização de produtos",
        "usuario": (this.$root.user != null ? this.$root.user : {id:this.$fpid,nome:"Usuário Anônimo"}),
        "dispositivo": "Web",
        "produto": this.adicionais,
        "resultadoQuery": [this.adicionais],
        "lat": 0,
        "lng": 0,
        "tela": "DetalhesProduto",
        "tempo": this.$utils.calcularDiferencaEmMinutos(moment().tz("America/Sao_Paulo").toDate(), this.dataEntrada),
        "dataEntrada": this.dataEntrada,
        "dataSaida": moment().tz("America/Sao_Paulo").toDate()
      }
      await this.$apiv3.post("/comportamentos", body);

    }
  },
};
</script>

<style lang="scss">
.p-button.p-button-text {
  color: $alternativeColor;
  font-size: 1rem;
}

.p-button.p-button-text:enabled:hover {
  color: $primaryColor;
  background: none;
}

.titulo {
  font-weight: bold;
  font-size: 1.5em;
  color: rgb(91, 91, 91);
  font-family: "Montserrat";
}
.cortado {
  text-decoration: line-through; /* Aplica o efeito riscado */
  /*color: red;                    /* Opcional: destaca o preço como incorreto */
}

.p-button {
  @media screen and (max-width: 500px) {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
}
</style>
