<template>

  <div class="produto-completo-component overflow-hidden">
    <div class="produto-completo-detalhes flex">
      <div class="col-fixed" style="width:65px" v-if="icone">
        <Image :src="icone" alt="Logo" class="produto-completo-profile-icon overflow-hidden border-circle block"
          style="--aspect-ratio: 1/1" imageClass="max-w-full h-auto block" />
      </div>
      <div class="col">
        <div
          class="produto-completo-titulo mb-1 text-xl font-bold white-space-nowrap overflow-hidden text-overflow-ellipsis">
          {{ nome }}
        </div>
        <div class="produto-completo-descricao">
          <div class="text-lg pb-0 font-light white-space-nowrap overflow-hidden text-overflow-ellipsis">
            {{ descricao }}
          </div>
        </div>
      </div>
      <div class="col-fixed" style="width: 130px" v-if="valorPromocao != ''">
        <span class="cortado mr-2">{{ valorAtacado }}</span>
        <span class="font-bold text-lg">{{ valorPromocao }}</span>
      </div>
      <div class="col-fixed" style="width: 130px">
        <div
          v-if="$root.empresa.configuracoes.ECOMMERCE_VALORES_ATACADO_VISIVEL || $root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO"
          class="produto-completo-preco text-xl font-bold pb-0 pr-0 text-right mt-1"
          v-tooltip.top="($root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO) ? { value: 'A partir de ' + $root.empresa.configuracoes.ECOMMERCE_VALORES_ATACADO_QUANTIDADE + ' ítens no pedido', class: 'text-xs' } : null">
          <span v-show="$root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO">A: </span>{{ valorAtacado }} <sup
            v-show="$root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO">*</sup>
        </div>
        <div
          v-if="$root.empresa.configuracoes.ECOMMERCE_VALORES_VAREJO_VISIVEL || $root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO"
          class="produto-completo-preco text-lg font-medium pb-0 pr-0 text-right">
          <span v-show="$root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO">V: </span>{{ valorVarejo }}
        </div>
      </div>
    </div>
    <div class="produto-completo-imagem" v-if="imagens" :style="'--quadrado: ' + w + 'px'">
      <Image :src="imagens[0]" alt="Produto" :preview="true" class="block" imageClass="max-w-full h-auto block" />
    </div>
    <div class="produto-completo-acoes text-center py-2">
      <Button label="Compartilhar" icon="pi pi-share-alt" class="p-button-text" @click="shareItem" />
      <Button label="Favoritar" icon="pi pi-heart-fill" class="p-button-text md:mx-4" @click="likeItem"
        v-if="favorito" />
      <Button label="Favoritar" icon="pi pi-heart" class="p-button-text md:mx-4" @click="likeItem" v-else />
      <Button label="Adicionar" icon="pi pi-shopping-cart" class="p-button-text" @click="addItem" />
    </div>
  </div>

  <Dialog :position="($utils.isMobileCheck()) ? 'bottom' : 'center'" v-model:visible="displayAdicionarCarrinho"
    style="max-width: 98%" :modal="true" :closable="true">
    <template #header>
      <h5 class="m-0 text-xl font-medium"><i class="pi pi-shopping-cart" style="font-size: 1.3rem"></i> Adicionar ao
        Carrinho</h5>
    </template>
    <AdicionarCarrinho :value="value.id" />
  </Dialog>

</template>

<script>

export default {
  props: {
    value: Object,
  },
  emits: [
    'add',
    'like',
    'share'
  ],
  name: "ProdutoCompleto",
  data() {
    return {
      displayAdicionarCarrinho: false,
      w: 0,
      favorito: false
    };
  },
  mounted() {
    this.w = this.$el.parentElement.offsetWidth;
    this.verificaFavorito();
  },
  computed: {
    nome() {
      if (this.value && typeof this.value.nome != "undefined" && this.value.nome) {
        return this.value.nome;
      }
      return "";
    },
    descricao() {
      if (this.value && typeof this.value.descricao != "undefined" && this.value.descricao) {
        return this.value.descricao;
      }
      return "";
    },
    imagens() {
      if (this.value && typeof this.value.imagens != "undefined" && this.value.imagens) {
        return this.value.imagens;
      }
      return [];
    },
    icone() {
      if (this.value && typeof this.value.icone != "undefined" && this.value.icone) {
        return this.value.icone;
      }
      return "";
    },
    valorAtacado() {
      if (this.value && typeof this.value.valorAtacado == "number") {
        return this.$utils.formatCurrency(this.value.valorAtacado);
      }
      return "";
    },
    valorVarejo() {
      if (this.value && typeof this.value.valorVarejo == "number") {
        return this.$utils.formatCurrency(this.value.valorVarejo);
      }
      return "";
    },
    valorPromocao() {
      //console.log(this.value.valorPromocao)
      if (this.value && typeof this.value.valorPromocao == "number") {
        if(this.value.valorPromocao <= 0){
          return "";
        }
        return this.$utils.formatCurrency(this.value.valorPromocao);
        
      }
      return "";
    },
  },
  methods: {

    addItem() {
      this.displayAdicionarCarrinho = true;
      this.$emit("add", this.value);
    },
    likeItem: async function () {
      // const res = await this.$api.get("/setfavorito/"+this.value.id);
      // const dados = res.data;
      // if(dados.success){
      //   this.favorito = dados.favorito;
      //   this.$emit("like", this.value);
      // }

    },
    verificaFavorito: async function () {
      // const res = await this.$api.get("/verificafavorito/"+this.value.id);
      // const dados = res.data;
      //   this.favorito = dados.success;

    },
    async shareItem() {
      let link =
        "https://loja.npc.software/" +
        this.$root.empresa.id +
        "#" +
        "/produto-detalhes/" +
        this.$root.empresa.id +
        "/" +
        this.value.id;

      let body = {
        "titulo": this.value.nome,
        "descricao": "Varejo: " + this.$utils.formatCurrency(this.value.valorVarejo) + " Atacado: " + this.$utils.formatCurrency(this.value.valorAtacado),
        "imagem": this.imagens[0],
        "url": link
      };
      const ret = await this.$apiv3.post("/v2/produtos-gerar-link-dinamico", body);

      window.open(
        "https://api.whatsapp.com/send?&text=" + (ret.data.success ? ret.data.data.shortLink : link)
      );

      this.$emit("share", this.value);
    },

  },

}
</script>

<style lang="scss">
.produto-completo-acoes,
.produto-completo-detalhes {
  background-color: $alternativeBackgroundColor;
  border-radius: 0.3rem;
}

.produto-completo-descricao>.col {
  max-width: calc(100% - 140px);
}

.produto-completo-detalhes>.col {
  max-width: calc(100% - 65px - 130px);
}
</style>

<style lang="scss" scoped>
.p-button.p-button-text {
  color: $alternativeColor;
}

.p-button.p-button-text:enabled:hover {
  color: $primaryColor;
  background: none;
}

.p-button {
  @media screen and (max-width: 500px) {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
}
</style>