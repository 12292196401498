<template>
  <div class="adicionar-carrinho-component relative" >

    <div v-if="carregando" class="adicionar-carrinho-carregando">
      Carregando informações.
    </div>

    <div v-else-if="cores.length > 0" class="grid mb-3 adicionar-carrinho-cores">
      <div class="col-12 font-medium">
        Selecione a cor:
      </div>
      <div v-for="c of cores" :key="c.id" :class="(cor == c.id)?'adicionar-carrinho-cor selecionada':'adicionar-carrinho-cor'" @click="cor = c.id">
        <Image :src="c.foto1" :alt="c.nome" class="adicionar-carrinho-cor-image overflow-hidden border-circle block" style="--aspect-ratio: 1/1" imageClass="max-w-full h-auto block" />
        <div class="adicionar-carrinho-cor-nome text-sm text-center">{{ c.nome }}</div>
      </div>
    </div>

    <div v-else class="grid mb-3 adicionar-carrinho-cores">
      <div class="col-12 mt-3 font-medium">
        Nenhum ítem disponível em estoque!
      </div>
    </div>

    <div class="grid mb-5 adicionar-carrinho-tamanhos" v-show="tamanhos.length > 0">
      <div class="col-12 font-medium">
        Selecione o tamanho:
      </div>
      <div v-for="t of tamanhos.sort((a, b) => a.timeStamp - b.timeStamp)" :key="t.id" :class="(tamanho == t.id)?'adicionar-carrinho-tamanho selecionado':'adicionar-carrinho-tamanho'" @click="tamanho = t.id">
        <span class="p-2">
          {{  t.nomeTamanho }}
          <span v-if="mostrarEstoque">({{ t.qnt }})</span>
        </span>
      </div>
    </div>

    <div class="grid mb-5" v-if="cores.length > 0">
      <div class="col-12 text-center font-medium">
        Quantidade:
      </div>
      <div class="col-12 text-center">
        <InputNumber 
          v-model="quantidade" 
          showButtons 
          buttonLayout="horizontal"
          decrementButtonClass="adicionar-carrinho-quantidade-button" 
          incrementButtonClass="adicionar-carrinho-quantidade-button" 
          incrementButtonIcon="pi pi-plus" 
          decrementButtonIcon="pi pi-minus" 
          mode="decimal"
          :min="1"
          inputClass="text-center"
        />
      </div>
    </div>

    <div class="grid mb-5" v-if="isObservacoes && cores.length > 0">
      <div class="col-12 text-center font-medium">
        Observação sobre o produto:
      </div>
      <div class="col-12 text-center">
        <Textarea 
          rows="2"
          v-model="observacao"
          class="w-full"
        />
      </div>
    </div>

    <div class="adicionar-carrinho-acoes" v-if="cores.length > 0">
      <div class="grid">
        <div class="col-7" v-if="$root.empresa.configuracoes.ECOMMERCE_PRODUTOS_ESTOQUE_VENDA_SEM && isAdicionarGrade">
          <Button 
            label="adicionar grade"
            icon="pi pi-shopping-cart" 
            @click="addGrade" 
          />
        </div>
        <div :class="isAdicionarGrade?'col-5 text-right':'col-12 text-center'">
          <Button 
            label="adicionar"
            icon="pi pi-shopping-cart" 
            class="p-button-success" 
            @click="gerenciarAddItem" 
            :disabled="!informacoesValidadas"
          />
        </div>
      </div>
    </div>

    <div class="loading" v-show="carregando">

      <div class="center-spinner" v-show="carregando">
        <ProgressSpinner />
      </div>

    </div>

    <div class="whatsapp" v-show="displayWhatsapp">

      <div class="center-whatsapp">
        <label for="whatsapp">Qual seu WhatsApp?</label>
        <InputMask id="whatsapp" v-model="$root.userWhatsapp" mask="(99) 9 9999-9999" /> 
        <Button class="p-button-success" label="OK" @click="displayWhatsapp = false; whatsappContinuidade()" />
      </div>

    </div>

  </div>

</template>

<script>

export default {
  name: "AdicionarCarrinho",
  props: [
    'value'
  ],
  data() {

    return {
      // Controle de apresentação
      carregando: true,
      informacoesValidadas: false,
      isAdicionarGrade: true,
      isObservacoes: false,
      mostrarEstoque: false,
      displayWhatsapp: false,
      whatsappContinuidade: () => {},

      // Dados
      quantidade: 1,
      tamanho: null,
      cor: null,
      valor: 0,
      observacao: "",

      //Chaves estrangeiras
      tamanhos: [],
      cores: [],
    };

  },
  emits: [
    'add',
  ],
  mounted() {
    this.carregaInformacoes();
  },
  watch: {
    cor: function(val) {
      if(val !== null) {
        const idx = this.cores.findIndex((e) => e.id == val);
        this.tamanhos = Object.values(this.cores[idx].tamanho);
        this.tamanho = this.tamanhos[0].id;
      } else {
        this.tamanhos = [];
      }
      this.validaInformacoes();
    },
    tamanho: function() {
      this.validaInformacoes();
    },
    quantidade: function() {
      this.validaInformacoes();
    },
    "$root.empresa": {
      handler: function (empresa) {
        if(empresa != null) {
          this.isAdicionarGrade = empresa.configuracoes.ECOMMERCE_PRODUTOS_GRADE_ADICIONAR;
          this.mostrarEstoque = empresa.configuracoes.ECOMMERCE_PRODUTOS_ESTOQUE_VISIVEL;
          this.isObservacoes = empresa.configuracoes.ORCAMENTOS_OBSERVACOES;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async carregaInformacoes() {

      this.carregando = true;

      const response = await this.$apiv3.get("/v2/produtos/"+ this.value);
      const dados = response.data;

      if(dados.success) {
        if(this.$root.empresa.configuracoes.ECOMMERCE_VALORES_VAREJO_VISIVEL || this.$root.empresa.configuracoes.ECOMMERCE_VALORES_DINAMICO){
          this.valor = dados.data.valorVarejo;
        }else{
          this.valor = dados.data.valorAtacado;
        }

        if(typeof dados.data.valorPromocao == "number" && dados.data.valorPromocao > 0){
          this.valor = dados.data.valorPromocao;
        }
        
        let cores = Object.values(dados.data.cor);

        if(this.$root.empresa.configuracoes.ECOMMERCE_PRODUTOS_ESTOQUE_VENDA_SEM == false) {
          
          for(const ic in cores) {
            const c = cores[ic];
            for(const [kt, t] of Object.entries(c.tamanho)) {
              if(t.qnt <= 0) {
                delete c.tamanho[kt];
              }
            }
            if(Object.keys(c.tamanho).length == 0) {
              delete cores[ic];
            }
          }

        }

        this.cores = cores.filter((o) => o);
        if(this.cores.length > 0)
          this.cor = this.cores[0].id;
      }

      this.carregando = false;

    },
    validaInformacoes() {
      this.informacoesValidadas = this.cor !== null && this.tamanho !== null && this.quantidade != 0;
    },
    validaObrigatoriedades(fnContinuidade) {

      if(this.$root.user == null) {

        if((this.$root.empresa.id == "UwgFwwDI1Igsnrd62RdWv4e8iB43" || this.$root.empresa.id == "hcC1vH80HeMwH4OmR3bbbaWpcTw1") && this.$root.userWhatsapp == "") {
          this.whatsappContinuidade = fnContinuidade;
          this.displayWhatsapp = true;
          return false;
        }

      }

      return true;

    },
    gerenciarAddItem: async function () {
      
      if (typeof this.$root.empresa.configuracoes.ECOMMERCE_DISPONIBILIDADE != "undefined" && this.$root.empresa.configuracoes.ECOMMERCE_DISPONIBILIDADE) {
       
        this.$toast.add({
          severity: "info",
          summary: this.$root.empresa.configuracoes.ECOMMERCE_DISPONIBILIDADE_TITULO != "" ?  this.$root.empresa.configuracoes.ECOMMERCE_DISPONIBILIDADE_TITULO : "Site indisponível",
          detail: this.$root.empresa.configuracoes.ECOMMERCE_DISPONIBILIDADE_MENSAGEM != "" ? this.$root.empresa.configuracoes.ECOMMERCE_DISPONIBILIDADE_MENSAGEM : "Em breve será aberto para compra",
          life: 5000,
        });
        return
      }
      this.addItem()
      
    },
   
    async addItem() {

      if(!this.validaObrigatoriedades(this.addItem)) return;

      this.carregando = true;

      const dados = {
        idCliente: (this.$root.user != null ? this.$root.user.id : ""),
        valor: this.valor,
        origem:"web",
        todos:false,
        idVendedor: (this.$root.vendedor == null)?null:this.$root.vendedor.id,
        idProduto:this.value,
        idCor: this.cor,
        idTamanho: this.tamanho,
        qnt: this.quantidade,
        descricao: this.observacao,
        //whatsapp: this.$root.userWhatsapp
      };

      const response = await this.$apiv3.post("/carrinhos/adicionar-produto", dados);

      this.carregando = false;
      let message = "";
      if(typeof response.data.debug != "undefined" && response.data.debug.error.length > 0){
        message = "Quantidade atual do estoque é: "+ response.data.debug.error[0].qntAtual;
      }else{
        message = response.data.message;
      }
      this.$toast.add({
        severity: (response.data.success)?"success":"error",
        summary: (response.data.success)?"Sucesso!":"Produto não adicionado!",
        detail: (response.data.success)?response.data.message:message,
        life: (response.data.success)?3000:5000,
      });

      if(response.data.success) {
        this.$root.carregaCarrinho();
        this.$emit("add");
      }

    },
    async addGrade() {

      if(!this.validaObrigatoriedades(this.addGrade)) return;

      this.carregando = true;

      const dados = {
        idCliente: (this.$root.user != null ? this.$root.user.id : ""),
        valor: this.valor,
        origem:"web",
        todos:true,
        idVendedor: (this.$root.vendedor == null)?null:this.$root.vendedor.id,
        idProduto:this.value,
        idCor: this.cor,
        idTamanho: this.tamanho,
        qnt: this.quantidade,
        descricao: this.observacao,
        //whatsapp: this.$root.userWhatsapp
      };

      const response = await this.$apiv3.post("/carrinhos/adicionar-produto", dados);

      this.carregando = false;

      this.$toast.add({
        severity: (response.data.success)?"success":"error",
        summary: (response.data.success)?"Sucesso!":"ERRO!",
        detail: response.data.message,
        life: (response.data.success)?3000:5000,
      });

      if(response.data.success) {
        this.$root.carregaCarrinho();
        this.$emit("add");
      }

    },

  },
};
</script>

<style lang="scss">

.adicionar-carrinho-carregando {
  padding: 40px;
}

.adicionar-carrinho-cores, .adicionar-carrinho-tamanhos {
  max-width: 445px;
}

.adicionar-carrinho-cor, .adicionar-carrinho-tamanho {
  padding: 0.5rem;
  cursor: pointer;
}

.adicionar-carrinho-cor-image {
  width: 45px;
  margin: 0 auto;
}

.adicionar-carrinho-tamanho span {
  background: #dddddd;
  border-radius: 4px;
}

.adicionar-carrinho-tamanho.selecionado span {
  background: var(--primaryBackgroundColor, $primaryBackgroundColor);
  color: white;
}

.adicionar-carrinho-cor.selecionada .adicionar-carrinho-cor-image {
  border: 3px solid var(--primaryBackgroundColor, $primaryBackgroundColor);
}

.adicionar-carrinho-quantidade-button {
  background-color: var(--primaryBackgroundColor, $primaryBackgroundColor) !important;
  border-color: var(--primaryBackgroundColor, $primaryBackgroundColor) !important;
}

</style>

<style lang="scss" scoped>

.whatsapp {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5)
}

.center-whatsapp {
  width: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 1rem;
}

.center-whatsapp label, .center-whatsapp input, .center-whatsapp button {
  display: block;
  width: 100%;
}

.center-whatsapp input {
  margin: 1rem 0;
}

.center-whatsapp label {
  font-weight: bold;
}

</style>